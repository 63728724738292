<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Dado" : "Cadastrar Dado" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
              v-if="labelBtn == 'Editar' ? false : true"
            >
              <v-text-field
                v-model="formTipoDado.id_tipo_dado"
                label="ID Tipo Dados"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-text-field
                v-model="formTipoDado.descricao"
                label="Descrição"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Status"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoDado.status"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Ativo Campanha"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoDado.ativa_campanha"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Ativa Comissão"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoDado.ativa_comissao"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()"> Fechar </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn == 'Salvar' ? send() : put()"
        >
          {{ labelBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import comissao from "@/services/http/comissaoService";

export default {
  name: "ModalTipoDado",

  mixins: [rules],

  props: {
    tipoDado: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      formTipoDado: {}
    };
  },

  watch: {
    tipoDado(value) {
      this.formTipoDado = value;
    }
  },

  methods: {
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },
    async send() {
      const validateStatus = this.$refs.form.validate();
      // console.log(this.formTipoDado);
      if (validateStatus) {
        await comissao()
          .tipoDados()
          .store(this.formTipoDado, {
            notification: true,
            message: "Tipo Dado cadastrado com sucesso!"
          });
      }

      this.close();
    },
    async put() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await comissao()
          .tipoDados(this.formTipoDado.id_tipo_dado)
          .update(this.formTipoDado, {
            notification: true,
            message: "Tipo Dado Editado com sucesso!"
          });
      }
      this.close();
    }
  }
};
</script>
