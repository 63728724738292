<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card class="pa-0">
      <v-container>
        <v-switch
          v-model="filters.status"
          true-value="S"
          false-value="N"
          :label="`${filters.status == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        ></v-switch>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
export default {
  name: "FiltroTipoDado",
  components: {
    FilterBottom
  },

  data() {
    return {
      filters: { status: "S" }
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
