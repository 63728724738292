<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="items"
      sort-by="tipo_dado"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Tipos de Dados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="103"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro @selectedFilters="fetchTipoDado((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.ativa_campanha`]="{ item }">
        <v-icon :color="statusItem(item.ativa_campanha).color">
          {{ statusItem(item.ativa_campanha).icon }}
        </v-icon>
      </template>

      <template v-slot:[`item.ativa_comissao`]="{ item }">
        <v-icon :color="statusItem(item.ativa_comissao).color">
          {{ statusItem(item.ativa_comissao).icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="statusFormated(item, 'status').color" dark>
          {{ statusFormated(item, "status").text }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="104"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="1581"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="104" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="1581"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalTipoDado
      :tipoDado="dado"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import IconBottom from "@/components/shared/bottons/IconBottom";
import Filtro from "./Filtro";
import ModalTipoDado from "./ModalTipoDado";
import comissao from "@/services/http/comissaoService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    Filtro,
    ModalTipoDado,
    RegisterBottom,
    IconBottom
  },

  data() {
    return {
      filters: {},
      labelBtn: "",
      dialog: false,
      dado: {},
      headers: [
        { text: "Descricao", value: "descricao" },
        { text: "Ativa Campanha", value: "ativa_campanha" },
        { text: "Ativa Comissão", value: "ativa_comissao" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false,
      status: {
        S: {
          icon: "mdi-check",
          color: "success"
        },
        N: {
          icon: "mdi-close",
          color: "error"
        }
      }
    };
  },

  methods: {
    statusItem(item) {
      return this.status[item];
    },
    editItem(item) {
      this.dado = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    statusFormated(tipoDado, status) {
      return {
        color: tipoDado[status] === "S" ? "green" : "red",
        text: tipoDado[status] === "S" ? "Ativo" : "Inativo"
      };
    },

    async fetchTipoDado(filters) {
      this.loading = true;
      const { data } = await comissao()
        .tipoDados()
        .show({
          per_page: -1,
          tipoDados: "S",
          ativo: "S",
          ...filters
        });
      this.items = data.data;
      this.loading = false;
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.fetchTipoDado(this.filters);
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar Dado?",
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .tipoDados(item.id_tipo_dado)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar valor fixo"
        });
      } finally {
        this.fetchTipoDado(this.filters);
      }
    }
  },
  async mounted() {
    await this.fetchTipoDado();
  }
};
</script>
