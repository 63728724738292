var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"loading":_vm.loading,"paginate":true,"items":_vm.items,"sort-by":"tipo_dado"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Tipos de Dados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(103),expression:"103"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('Filtro',{on:{"selectedFilters":function($event){_vm.fetchTipoDado((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.ativa_campanha",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusItem(item.ativa_campanha).color}},[_vm._v(" "+_vm._s(_vm.statusItem(item.ativa_campanha).icon)+" ")])]}},{key:"item.ativa_comissao",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusItem(item.ativa_comissao).color}},[_vm._v(" "+_vm._s(_vm.statusItem(item.ativa_comissao).icon)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusFormated(item, 'status').color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusFormated(item, "status").text)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(104),expression:"104"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(1581),expression:"1581"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)}),_c('ModalTipoDado',{attrs:{"tipoDado":_vm.dado,"dialog":_vm.dialog,"labelBtn":_vm.labelBtn},on:{"close":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }